/*
//copyright: Big Screen Entertainment Group Inc.*/
/* Footer */

.footer {
    padding: 10px 1%;
    float: left;
    width: 48%;
}

.footer-menu-item {
    color: rgba(224, 45, 39, 0.35);
    cursor: pointer;
}

.footer-center {
    width: 100%;
    float: left;
    padding: 10px 0 100px 0;
}

.mc400 {
    max-width: 302px;
    margin: 0 auto;
}

.footer-link {
    padding: 10px;
    margin: 10px auto;
    border-radius: 5px;
    max-width: 300px;
    border: 2px solid #FFF;
}

.footer-link:hover {
    background-color: rgba(255, 255, 255, 0.10);
}


@media screen and (max-width: 768px) {

    .footer {        
        width: 98%;
    }


}