.tier_outline_outer {
    margin: 0 0 1rem 0;
    border: 1px solid #fff;
    border-radius: 8px;
    background-color: #000;
    padding: 40px 0;
}

.tier_outline_inner {    
    padding: 0 1em;
    margin: 0 auto;
}


.tier_outline_split {
    border-top: 1px solid #fff;
    margin: 0 auto;
}

.tier_purchase {

    max-width: 400px;
    margin: 0 auto;    
}

.tier_purchase input {
    margin:  1em 0 !important;
}



.newsletter {
	width: 80%;
    left: 20%;
    top: 60px;
    position: relative;
    float: left;
    z-index: 100;
    padding: 0;
    margin: 0;
    text-align: center;
}

.message_error {
    color: #e02d27;
}

.message_success {
    color: #00b4f9;
}

.subscription-details {
    min-height: 210px;
    width: 100%;
}

.subscription-cancel-spinner {
    position: relative;
    left: 40%;
}

@media screen and (max-width: 1024px) {
    .newsletter {
        left: 0%;
        width: 100%;
    }
}