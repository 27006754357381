.modtop1 {

    top: 60px;
    position: relative;
    float: left;
    z-index: 100;
    padding: 0;
    margin: 0;
    text-align: center;
}

.modtop1 img {
    width: 100%;
}

/*.carousel.carousel-slider img {
    max-height: 1130px;
}*/

.carousel.carousel-slider .control-arrow {
    margin: 0 !important;
}

.carousel .slide .play-btn {
    position: absolute;
    cursor: pointer;
    bottom: 20px !important;
    left: 76% !important;
    margin-left: 0% !important;
    opacity: 0.75;
    transition: opacity .2s ease;
    width: 15% !important;
    max-width: 130px;
}

.carousel .slide .play-btn:hover {
    opacity: 1;
}

@media screen and (max-width: 1024px) {

    .control-arrow {
        display: none;
    }

    .modtop1 {
        left: 0%;
        width: 100%;
    }
}

@media screen and (min-width: 1025px) {
    .carousel img {
        width: 80% !important;
    }
}

@media screen and (min-width: 1800px) {
    .carousel img {
        width: 70% !important;
    }
}


@media screen and (min-width: 2440px) {
    .carousel img {
        width: 60% !important;
    }
}