/*
//copyright: Big Screen Entertainment Group Inc.*/


.player-container {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.player-wrapper {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.25s linear;
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
    /*background: radial-gradient(transparent, black), #bbb;*/
    background: #101010;

}

.player-no-wrapper {
    visibility: hidden;
    opacity: 0;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.player-overlay {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.25s linear;
    position: relative;
    /*padding: 1rem;*/
    /*background: rgba(0,0,0,0.5);*/
    z-index: 1;
    float: left;
}

.player-no-overlay {
    visibility: hidden;
    opacity: 0;
}

.player-overlay-thumbnail,
.player-overlay-info {
    position: relative;
    padding: 1% 1% 1% 0;
}

.player-overlay-info {
    width: 78%;
}

.player-controls {
    position: relative;
    float: left;
    width: 100%;
    padding: 0.25rem 0;
}

.player-controls button {
    text-decoration: none;
    border: 2px solid rgba(249, 45, 39, 0.8);
    position: relative;
    overflow: hidden;
    background-color: transparent;
    transition: all 0.20s linear;
    border-radius: 0;
    outline: none;
    width: 18%;
    min-width: 120px;
    max-width: 160px;
    min-height: 30px;
    margin: 0;
    left: 0;
    font-weight: 700;
    border-radius: 9px;
}


.player-controls button:hover {
    /*box-shadow: inset 5px 5px 10px 0px  rgba(0,0,0,1);*/
    background-color: rgba(249, 45, 39, 0.3);
    text-shadow: 3px 3px 2px #000;
}

.player-controls button:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg,
        transparent,
        rgba(249, 45, 39, 0.4),
        transparent);
    transition: all 0.20s;
}

.player-controls button:hover:before {
    left: 100%;
}

.player-overlay-content {
  float: left;
}

.player-overlay-background {
    
}


.player-overlay-buttons {
    position: relative;
    float: left;
    max-width: 280px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.10);
    padding: 10px;
    margin: 0 10px 10px 10px;
    text-align: center;
    box-shadow: 6px 3px 11px 5px rgba(0,0,0,0.5);
}

.player-overlay-description {
  position: relative;
  float: left;
  max-width: 500px;
  padding: 10px 10px 10px 0;
}

/*.player-overlay-thumbnail:after {
  content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 25px 45px #000;
}*/


.player-overlay-thumbnail img {
    max-width: 580px;
    border-radius: 9px;
}

.player-container button {
    margin: 0;
    left: 0;
}


h1 {
    margin: 0 0 0.5rem 0 !important;
}

video {
    outline: none;
}


@media screen and (max-width: 768px) {

    .player-overlay-description {
        position: relative;
        left: 50%;
        translate: -50%;
    }

    .player-overlay-background {
        padding-top: 0;
    }

    .player-overlay-content {
        width: 100%;

    }
    .player-overlay-thumbnail {
        margin: 0 auto;
        max-width: 350px;
        float: none;
        padding: 10px 5px;
    }

    .player-overlay-thumbnail img {
        width: 100%
    }

    .player-overlay-info {
        width: 100%;
    }

    .player-overlay-buttons {
        left: 50%;
        translate: -50%;
    }

    .player-controls {
        padding: 2rem 0rem;
    }


    .player-controls button {
        max-width: 48%;
        min-height: 24px;
        font-size: initial;
    }
}