/*.video-js {
	width: 100%;
	height:  100%;
}*/

/*.video-js .vjs-tech {
	position: relative;
	width: auto;
	height: auto;
}*/

/*.video-js .vjs-big-play-button {
	z-index: 9999;
	display:  none;
}*/

/*.ima-ad-container {
	bottom:  100%;
}*/

/*
.vjs_video_3_ima-ad-container > div {
	position: relative;
	width:  100%;
	height: 100%;
}
*/
button {
	margin: 0 !important;
}

.bse-buttons {
	margin: 5px 0px 5px 0 !important;
    max-width: 80px;
    border-radius: 40px;
    transition: background-color .2s ease;
}

.bse-buttons-play {
    padding: 15px 13px 14px 16px;
}

.bse-buttons-play::before {
	content: '\25BA';
    font-size: 40px;
    font-family: auto;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.bse-buttons-playlist-add {
	padding: 4px 15px 3px 14px;
}

.bse-buttons-playlist-add::before {
	content: '\002B';
    font-size: 60px;
    font-family: auto;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5)
}

.bse-buttons-playlist-remove {
	padding: 4px 15px 3px 14px;
}

.bse-buttons-playlist-remove::before {
	content: '\2212';
    font-size: 60px;
    font-family: auto;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5)
}

.thirdparty-buttons {
/*	min-width: 250px;	*/
/*	padding: 10px;*/
}

.playlist_true {
	background-color: #820f0f;
}

.playlist_true:hover {
	background-color: red;
}

.playlist_false {
	
}

.button_disabled {
	background-color: #666;
	cursor: default;
}

.button_disabled:hover {
	background-color: #666;
}

.video-hidden {
	display: none;
}

.video-js .vjs-control-bar {
    height: 4em;
}

.vjs-button > .vjs-icon-placeholder:before {
	height: 2.5em;
}

.vjs-button>.vjs-icon-placeholder:before {
    font-size: 2.5em;
}

.video-js .vjs-control {
    width: 4em !important;
    margin-left: 2em;
}

.video-js .vjs-time-control {
	min-width: 6em;
}

.video-js button {
	border-radius: 0;
}

.vjs-volume-bar.vjs-slider-horizontal {
    height: 0.8em;
}

.video-js .vjs-time-control {
    font-size: 1.3em;
}

.video-js .vjs-fullscreen-control {
	margin-right: 2em;
}

.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover,
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active,
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
	width: 10em !important;
}


@media screen and (max-width: 768px) {	
    .bse-buttons button {
    	max-width: 100%;
    }
}
