.user_profile {
	/*width: 80%;*/
}

.user_profile, .user_actions, .user_info, .user_subscriptions {
	float: left;
	margin-bottom: 1rem;
}

.user_actions, .user_info, .user_subscriptions {
	padding: 1rem;
	margin: 1rem 1rem 0rem 0 !important;
	border-radius: 8px;
	background-color: rgba(255,255,255,0.10);
	width: 100%;
}

.nomargin_right {
    margin-right: 0rem;
}

.register {
	margin-top: 4rem;
	float:  left;
}






@media screen and (max-width: 768px) {

    .column {
        width: 100%;
    }
    .column-25 {
        width: 100%;
    }
    .column-50 {
        width: 100%;
    }
    .column-75 {
        width: 100%;
    }
}



@media screen and (max-width: 1024px) {

	.user_profile {
		/*width: 60%;*/
		margin: 0 auto;
	}
}

@media screen and (max-width: 768px) {

	.user_profile {
		width: 100%;
	}
}