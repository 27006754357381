

p {
    width: 100%;
    float: left;
}

.message_error {
    color: #e02d27;
}

.message_success {
    color: #00b4f9;
}



@media screen and (max-width: 1024px) {
    .newsletter {
        left: 0%;
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .pleft {
        max-width: 350px !important;

    }
}