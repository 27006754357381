
.user-status {
	cursor: pointer;
    float: left;
    padding: 10px 10px 7px 10px;
    margin: 0.35rem 0.35rem 0.25rem 0.25rem;
    transition: background-color 0.25s ease;
    border-radius: 3px;
}

.user-status:hover {
	background-color: rgba(255, 255, 255, 0.30);
}

.login:hover {
	
}

.loader-placeholder {
    width: 50px;
    min-width: 50px;
    height: 50px;
    float:left;
    position: relative;
    right: 60px;
    bottom: 10px;
}

.user-status img {
    width: 25px;
}

@media screen and (max-width: 1024px) {
    .user-status {
        
    }
}