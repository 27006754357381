.search-container {
    max-width: 300px;
    position: fixed;
    bottom: 0;
    z-index: 998;
    right: 1px;
    text-align: center;
    display: inline-flex;
}


.search_header {
    font-weight: 800;
    font-size: 200%;
    margin: 1rem 0 0.5rem 0;
    text-shadow: 2px 2px 0px rgb(200 200 200 / 35%);
}

.search_input {
    width: 100% !important;
    font-size: max(18px, 0.90em) !important;
    font-family: inherit !important;
    padding: 5px !important;
    margin: 1px auto !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
/* TILE CSS MEDIA */
.tile-container {
    padding: 0 1rem;
}





.tile-standard {
    /*background-color: rgba(200,200,200,0.1);  */
    flex: 0 0 20%;
    /*padding: 0.25rem 0;*/
    margin: 1rem 0;
    /*width: 25%;*/
}

.tile-fill {
    width: 100%;
}

.tile-standard:first-child {
    /*margin: 1rem 0;*/
}

.tile-standard img {}


.hide_link {
    display: none;
}


.center {
    margin: 0 auto;
    text-align: center;
}

.tile-left {
    text-align: left;
    float: left;
}


.tile-image {
    
}

.tile-image img {    
    width: 95%;
    border: 2px rgba(255, 255, 255, 0.25) solid;
    transition: border 0.15s ease;
}

.tile-image img:hover {
    border: 2px rgba(255, 255, 255, 0.75) solid;
}


.tile-title {
    position: relative;
    background-color: ;
    margin: 0;
    bottom: 0;
    padding: 0 0 2px 4px;
    transition: background-color 0.15s ease;
    font-size:  125%;
}

.tile-menu-item {
    color: #e02d27;
    cursor: pointer;
    position: relative;
    margin: 0px;
}

.tile-tile:hover {
    background-color: 2px solid rgba(200, 200, 200, 0.7);
}

.tile-standard:hover h5 {
    color: #00b4f9;
}



@media screen and (max-width: 1519px) {
    .tile-standard {
        flex: 0 0 25%;
    }

    .tile-title {
        font-size: 80%;
    }

    .tile-container h2 {
        font-size: 160%;
    }
}


@media screen and (max-width: 1154px) {
    .tile-standard {
        flex: 0 0 33.33%;
    }
}

@media screen and (max-width: 768px) {

    .tile-title {
        font-size: 80%;
    }

    .tile-mobile {
        flex: 0 0 100%;
    }
}

@media screen and (max-width: 580px) {
    

    .tile-standard {
        flex: 0 0 50%;
    }

}