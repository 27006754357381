/*
//copyright: Big Screen Entertainment Group Inc.*/
/* Header */


.nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 30px;
}

.nav-menu {
    float: left;
}

.nav-float-right {
    float: right;
}

.nav-button {
    cursor: pointer;
    padding: 18px;
    margin: 0.2rem 0.25rem 0.25rem 0.25rem;    
    transition: all 0.25s ease;
    border-radius: 50px;
    background-color: #000;
    border: 4px solid #FFF;
    position: fixed;
    bottom: -22px;
    left: -22px;
}

.nav-button:hover {
    background-color: #fff;
    border: 4px solid #888;
}

.nav-button img {
    width: 35px;
    vertical-align: middle;

}

.nav-breadcrumb {
    float: left;
    width: 280px;
    text-align: center;
}

.nav-breadcrumb p {
    padding: 4px 0;
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
}
