
.newsletter {
	width: 80%;
    left: 20%;
    top: 60px;
    position: relative;
    float: left;
    z-index: 100;
    padding: 0;
    margin: 0;
    text-align: center;
}

.pleft {
    float: left;
}

.disabled {
    cursor: default !important;
    background-color: #999 !important;
}

@media screen and (max-width: 1024px) {
    .newsletter {
        left: 0%;
        width: 100%;
    }
}