

.show-items-wrapper {
    position: relative;
    margin-bottom: 2rem;
}

.tile-row-standard {
    display: flex;
    flex-direction: row;
    justify-content: left;
    overflow: scroll;
    -webkit-overflow-scrolling: touch; 
}

.tile-row-category {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    overflow: scroll;
    -webkit-overflow-scrolling: touch; 
}

.tile-row-standard::-webkit-scrollbar {
    width: 0 !important;
}

.tile-row-category::-webkit-scrollbar {
    width: 0 !important;
}

.show-items {
    text-align: center;
    cursor: pointer;
    color: #e02d27;
}

.show-items:hover {
    color: #00b4f9;
}

.tile-row-standard {
    scroll-behavior: smooth;
}

.right {    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 35px;
    position: absolute;
    height: 100%;
    top: 0;
    right: -15px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.left {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 35px;
    top: 0;
    position: absolute;
    height: 100%;
    left: -15px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.right:before {}

.right:hover:before {}

.left:before {}

.left:hover:before {}

.title {
    font-weight: 800;
}

.ratio {
    color: #ccc;
}


@media screen and (max-width: 1024px) {
    .show-more {

    }
}