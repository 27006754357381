
/*copyright: Big Screen Entertainment Group Inc.*/
/* SEARCH MENU */

h6 {
    color: #aaa;
}

.menu-overflow {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: rgb(0,125,172);
    background: linear-gradient(360deg, rgba(0,125,172,1) 0%, rgba(0,125,172,0.25) 65%, rgba(0,125,172,0) 100%);
}


.menu-overflow-scrollbottom {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 20px;
    background: rgb(0,125,172);
    background: linear-gradient(180deg, rgba(0,125,172,1) 0%, rgba(0,125,172,0.5) 65%, rgba(0,125,172,0) 100%);
    z-index: 1;
}



.menu-container {
    height: 100%;
    width: 100%;
    position: fixed;
    /*top: 60px;*/
    background-color: rgba(15, 15, 15, 0.65);
    z-index: 999;
    min-height: 100%;    
    overflow: scroll;
}
/* hide scrollbar on menu for major browsers */
.menu-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.menu-container { overflow: -moz-scrollbars-none; }
.menu-container { -ms-overflow-style: none; }


.search-menu-container {
    height: 100%;
    width: 20%;
    min-width: 300px;
/*    background-color: black;*/
    /*min-height: 100%;*/
    /*padding: 0.35rem 0 0 0;*/
    /*overflow: scroll;*/
}
/* hide scrollbar on menu for major browsers */
/*.search-menu-container::-webkit-scrollbar {
    width: 0px;
}
.search-menu-container { overflow: -moz-scrollbars-none; }
.search-menu-container { -ms-overflow-style: none; }
*/



.submenu-fixed {
    /*justify-content: 'center';*/
    cursor: pointer;
    padding: 18px;
    margin: 0.2rem 0.25rem 0.25rem 0.25rem;
    transition: all 0.25s ease;
    border-radius: 50px;
    background-color: #000;
    border: 4px solid #FFF;
    position: fixed;
    top: -19px;
    left: -21px;
}

.submenu-toggle {
    cursor: pointer;
    padding: 18px;
    margin: 0.2rem 0.25rem 0.25rem 0.25rem;
    transition: all 0.25s ease;
    border-radius: 50px;
    background-color: #000;
    border: 4px #FFF solid;
    position: fixed;
    top: -22px;
    right: -22px;
}

.submenu-fixed:active, .submenu-toggle:active {    
    /*background-color: red;*/
}


.submenu-fixed:hover, .submenu-toggle:hover {
    background-color: #fff;
    border: 4px #888 solid;
}

.submenu-fixed img, .submenu-toggle img {
    width: 30px;
    vertical-align: middle;
}


.search-menu-list {
    width: 250px;
    /* height: 50%; */
    color: white;
    font-size: 18px;
    background-color: black;
    /* min-height: 409px; */
    /* overflow: scroll; */
    /* padding-bottom: 0.1rem; */
    float: left;
    background-repeat: no-repeat;
    background-position: top left;
    border: 2px #fff solid;
    border-radius: 10px;
    position: relative;
    left: 80px;
    top: 20px;
}

/* hide scrollbar on menu for major browsers */
/*.search-menu-list::-webkit-scrollbar {
    width: 0px;
}
.search-menu-list { overflow: -moz-scrollbars-none; }
.search-menu-list { -ms-overflow-style: none; }*/



.search-menu-item {
    font-size: 18px;
    line-height: 35px;
    font-style: normal;
    letter-spacing: 0px;
    padding: 0.3rem;
    cursor: pointer;
    color: #ccc;
    background-color: rgba(0, 125, 172, 0.35);
    margin: 0.25rem 0.25rem 0.25rem 0.25rem;
    font-weight: 500;
    text-shadow: 1px 1px 0px rgb(100 100 100 / 35%);
    border-radius: 5px;
    transition: background-color 0.25s ease;
    text-align: center;
}

.search-menu-item:last-of-type {
    margin: 0 0.25rem 0.25rem 0.25rem;
}



.sub-menu {
    font-size: 18px;
    line-height: 30px;
    font-style: normal;
    letter-spacing: 0px;
    padding: 0.55rem;
    cursor: pointer;
    color: #ccc;
    background-color: rgba(224, 45, 39, 0.35);
    margin: 0.25rem 0.25rem 0.25rem 0.25rem;
    font-weight: 500;
    text-shadow: 1px 1px 0px rgb(100 100 100 / 35%);
    border-radius: 5px;
    transition: background-color 0.25s ease;
    text-align: center;
}

.search-menu-item:hover {
    color: #fff;
    background-color: rgba(0, 125, 172, 0.75);
}

.sub-menu:hover {
    color: #fff;
    background-color: rgba(224, 45, 39, 0.75);
}

.sub-menu-active {
    color: #fff;
    background-color: rgba(224, 45, 39, 0.75);
}

.search-menu-active {
    color: #fff;
    background-color: rgba(0, 167, 230, 0.75);
}


.sub-menu img {
    width: 18px;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem;
    float: right;
}

.menu-img {    
    margin: 0 0.35rem 0.35rem 0.35rem;
}

.menu-img img {
    width: 100%;
    border-radius: 3px;
    min-height: 48px;
}

.search-menu-img {
    width: 18px;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem !important;
    float: left !important;
    vertical-align: bottom;
}


.submenu-search {
    padding: 0.5rem 0 0.5rem 0.5rem;
    /*border-top: 1px solid rgba(15, 15, 15, 0.5);*/
    vertical-align: middle;
    display: none;
}

.submenu-search img {
    padding: 0.5rem;
}

.submenu-search input {
    padding: 0.5rem;
    vertical-align: top;
    width: 80%;
}

.stock-symbol {
    padding: 0 0 1rem 0;
    font-size: 20px;
}



@media screen and (max-width: 768px) {
    .search-menu-container {
        width: 100%;
    }

    .search-menu-item,
    .sub-menu,
    .sub-menu-item {
        text-align: center;
    }

    /*.search-menu-img {
        float: none !important;
        margin: 0 auto;
    }*/

    .menu-img {
        margin: 0.35rem;
        width: 280px;
        float: left;
    }
    .stock-symbol {
        float: left;
    }

    .search-menu-list {
        width: 225px;        
        position: absolute;
        left: 50%;
        top: 60px;
        transform: translate(-50%);
    }
}




/* SUB MENU STYLING */
/*
//copyright: Big Screen Entertainment Group Inc.*/


h6 {
    color: #aaa;
}

.sub-menu-container {
    /*height: 100%;*/
    width: 100%;
    /*width: 90%;*/
    position: relative;
    /*left: 10%;*/
    /*background-color: rgba(15, 15, 15, 0.65);*/
    /*z-index: 1;*/
}

.sub-menu-list {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 18px;
    background-color: black;
    /*border-right: 2px double rgba(255,255,255,0.15);*/
    /*border-top: 2px double rgba(255,255,255,0.15);*/
    /*padding-top: 0.5rem;*/
}

.sub-menu-item {
    font-size: 18px;
    line-height: 30px;
    font-style: normal;
    letter-spacing: 0px;
    padding: 0.5rem;
    cursor: pointer;
    color: #ccc;
    background-color: rgba(224, 45, 39, 0.35);
    margin: 0 0.25rem 0.25rem 0.25rem;
    font-weight: 500;
    text-shadow: 1px 1px 0px rgb(100 100 100 / 35%);
    border-radius: 8px;
    transition: background-color 0.25s ease;
    text-align: center;
}



.sub-menu-item:hover {
    color: #fff;
    background-color: rgba(224, 45, 39, 0.75);
}

.sub-menu-item-active {
    color: #fff;
    background-color: rgba(224, 45, 39, 0.75);
}




@media screen and (max-width: 1136px) {
    /*.sub-menu-list {
        min-width: 225px;
    }*/
}

