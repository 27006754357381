/*
//copyright: Big Screen Entertainment Group Inc.*/
/* Header */


.header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header-submenu {
    float: left;
}




.logo-container {
    margin: 0;
    padding: 0;
}

.logo-container img {
    max-height: 42px;
    position: relative;
    top: -2px;

}


.App-logoicon {
    margin: 0;
}

.App-logoicon img {
    max-height: 4em;
}

.stretch {
    width: 100%;
    display: inline-block;
    font-size: 0;
    line-height: 0
}


.headholder {
    width: 100%;
    height: 100px;
    position: relative;
    bottom: 85px;
    left: -15px;
    z-index: -1;
}


.circle {
    position: absolute;
    width: 105%;
    height: 40px;
    background: radial-gradient(32px, transparent, transparent 4px, #fff 0px, #fff 22px, transparent 24px);
    background-size: 40px 60px;
}

.circle2 {
    /* Offset to make squigglies line up */
    top: 20px;
    left: 15px;
    background-position: 0px -20px;
}
