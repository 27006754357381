.clear_all {
    color: inherit;
}

.clear_all:hover {
    color: inherit;
}

.grecaptcha-badge {
    visibility: hidden;
}

html,
body {
    width: 100%;
    height: 100%;    
}

:is(h1, h2, h3, h4, h5, h6) {

    width: 100%;
}

h1 {
    font-weight: 800;
    color: #e02d27;
    font-size: 200%;
    margin: 0 0 0.5rem 0 !important;
    text-shadow: 2px 2px 0px rgba(200, 200, 200, 0.35);
}

h2 {
    font-weight: 800;
    font-size: 165%;
    margin: 0 0 0.5rem 0;
    text-shadow: 2px 2px 0px rgba(200, 200, 200, 0.35);
}

h3 {
    font-size: 125%;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
    text-shadow: 2px 2px 2px rgba(200, 200, 200, 0.35);
}

h4 {
    font-size: 100%;
    font-weight: 600;
    margin: 0.5rem 0 0.5rem 0;
}

h5 {
    margin: 0.4rem 0 0.2rem 0;
}

h6 {
    margin: 0.25rem 0 0 0;
}

div {
    padding: 0;
    margin: 0;
}

form {
    width: 100%;
}

.fclear {
    float: none;
}

.col-100 {
    width: 100%;
}

.justify {
    text-align: justify;
}

input[type="text"], input[type="password"], input[type="email"], input[type="search"] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    max-width: 300px;
    font-size: 14px;
    font-size: max(14px, 1em);
    font-family: inherit;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem auto;
    border: 2px solid #fff;
    border-radius: 5px;
    background-color: #fff;
    transition: background-color 0.2s ease;
    
}


input[type="submit"], input[type="button"], button {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    font-size: 14px;
    font-size: max(14px, 1em);
    font-family: inherit;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
    border: 2px solid #fff;
    border-radius: 5px;
    background-color: #fff;
    transition: background-color 0.2s ease;
    
}



input[type="text"]:hover, input[type="password"]:hover, input[type="email"]:hover, input[type="button"]:hover, button:hover {
    background-color: rgba(232, 240, 254, 0.8);
}


input[type="submit"], button {
    position: relative;
    color: #fff;
    background-color: #1a566c;
    max-width: 280px;
    cursor: pointer;
    font-weight: 600;
    margin: 5px 5px 5px 0 !important;
}

input[type="submit"]:hover, button:hover {
    background-color: #00b4f9;
}

label {

}


a, .link {
    text-decoration: none;
    color: #e02d27;
    transition: all 0.25s ease;
}

a:hover {
    color: #00b4f9;   
}

.link {
    text-decoration: none;
    color: #e02d27;
    cursor: pointer;
}

.link:hover {
    color: #00b4f9;   
}

p {
    margin: 0.5em 0;
}

.grecaptcha-badge {
    z-index: 1000;
}

#identify {
    
}

/* MAIN */
.App {
    /*text-align: center;*/
    background-color: #000000;
    display: block;
    height: 100%;
    width: 100%;
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        "sans-serif";
    min-height: 100%;
    color: #fff;
    float: left;
}

header {
  height: 40px;
  position: fixed;
  background-color: #000;
  z-index: 999;
  width: 100%;
  float: left;
  border-bottom: 4px solid #fff;
}

main {
    width: 100%;
/*    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 65%), url(https://link.storjshare.io/juazrf542pdkznit4qgpcpyudd6a/big-stream-v1/thumbnails/bg_mediaview.jpg?view);*/
    position: relative;
    float: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    background-size: 90% auto;
    padding: 40px 0;
}

nav {
    height: 40px;
    position: fixed;
    background-color: #000;
    z-index: 499;
    width: 100%;
    float: left;
    bottom: 0;
    border-top: 4px solid #fff;
}

.wrapper-container {
    position: relative;
    top:  60px;
}

.main-container {
    padding: 0 5%;
    margin: 0 auto;
}

.container {
    margin: 0 auto;
    width: fit-content;
}


.ads_enabled {
    width: 80%;
    /*left: 20%;*/  /*this is moved over because of left and right ads */
    position: relative;
/*    float: left;*/
}

.ads_disabled {
    width: 100%;
    left: 0;
    position: relative;
}

.ads_component_enabled {
    /*width: 20%;  for showing ads */
    display: block; /* display it because its enabled */
}

.modleft {
    position: relative;
    width: 20%;
    float: left;
}

.modright {
    position: relative;
    width: 20%;
    float: left;
}

.modadtop1 {
    text-align: center;
}

.ads_component_disabled {
    display: none;
}


.modtop-container {
    
}


footer {
    width: 100%;    
    float: left;
    text-align: center;
    position: relative;
    top: 60px;
    z-index: 200;
    border-top: 4px solid #FFF;
}

@media screen and (min-width:  1520px) {
    .ads_disabled {
        width: 80%;
    }
}


@media screen and (max-width: 1520px) {
    footer {
        width: 100%;
        left: 0;
    }

    /*main, .modtop-container {
      width: 90% !important;
      margin: 0 5% !important;
    }*/

    input, button {
        /*width: 90% !important;*/
        left: 0;
    }

    .ads_component_enabled {
        width: 100% !important;
        position: relative;
        float: left;
    }

    /* input[type="submit"], button {
        float: left;
    }*/
}

@media screen and (max-width: 768px) {
    
    form {
        text-align: center;
    }

    input, button {
        width: 100% !important;        
    }
    
    h1 {
        font-weight: 800;
        color: #e02d27;
        font-size: 200%;
        margin: 0 0 0.5rem 0;
    }

    /*main {
        width: 95%; 
    }*/

    /*.ads_component_enabled {
        width: 0;
    }*/
}
