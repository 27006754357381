/* flex standard */
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    float: left;
}


.summary_wrapper {
	border-radius: 8px;
	padding: 8px;
}

.summary_wrapper:nth-child(even) {
	background-color: rgba(25,25,25);
}

article {
	width: 100%;
}

.column {
	width: 100%;
}

.column-25 {
	width: 25%;
}

.column-50 {
	width: 50%;	
}

.column-75 {
	width: 75%;
}

.w100 {
	width: 100%;
}

.mh350 {
	max-width: 600px;
}

.pad_t10 {
	padding: 10px 0;
}

.intro {
	width: 50%;
}

.h1red {
	color: #e02d27;
}

.white {
	color: #fff;
}

.director {
	/*width: 600px;*/
	min-height: 175px;
	padding-right: 1em;
}

.flag_england::after { 
    content: url('./_images/flag_england.png');
}

.flag_unitedstates::after { 
    content: url('./_images/flag_unitedstates.png');
}

.flag_italy::after { 
    content: url('./_images/flag_italy.png');
}

.flag_japan::after { 
    content: url('./_images/flag_japan.png');
}

.hitchcock::before {
	content: url('./_images/article00002_dir_alfred_hitchcock.png');
	float: left;
	margin-bottom: 10px;
}

.spielberg::before {
	content: url('./_images/article00002_dir_steven_spielberg.png');
	float: left;	
	margin-bottom: 10px;
}

.chaplin::before {
	content: url('./_images/article00002_dir_charlie_chaplin.png');
	float: left;
	margin-bottom: 10px;
}

.welles::before {
	content: url('./_images/article00002_dir_orson_welles.png');
	float: left;
	margin-bottom: 10px;
}

.bigelow::before {
	content: url('./_images/article00002_dir_kathryn_bigelow.png');
	float: left;
	margin-bottom: 10px;
}

.fellini::before {
	content: url('./_images/article00002_dir_federico_fellini.png');
	float: left;
	margin-bottom: 10px;
}

.kurosawa::before {
	content: url('./_images/article00002_dir_akia_kurosawa.png');
	float: left;
	margin-bottom: 10px;
}

.ford::before {
	content: url('./_images/article00002_dir_john_ford.png');
	float: left;
	margin-bottom: 25px;
}

.cameron::before {
	content: url('./_images/article00002_dir_james_cameron.png');
	float: left;
	margin-bottom: 10px;
}

.demille::before {
	content: url('./_images/article00002_dir_cecil_demille.png');
	float: left;
	margin-bottom: 10px;
}


@media screen and (max-width: 1024px) {

	article {
		width: 80%;
		margin: 0 auto;
	}

	.intro {
		width: 100%;
	}

	.director {
		width: 400px;
	}
}

@media screen and (max-width: 768px) {

	article {
		width: 100%;
	}

	.director {
		width: 100%;
	}
	.column {
		width: 100%;
	}
	.column-25 {
		width: 100%;
	}
	.column-50 {
		width: 100%;
	}
	.column-75 {
		width: 100%;
	}
	.ford::before {
		min-height:155px;
	}
}